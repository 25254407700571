.moving-text-container {
  width: 100dvw; 
  overflow: hidden; 
}

.moving-text {
  font-size: 24px; 
  white-space: nowrap;
  color: white; 
}

.gradient-text {
      background-image: linear-gradient(
        to right,
        #ffffff 10%,
        #00d5ff 40%,
        #0062ff 50%,

      ) !important;
      -webkit-background-clip: text !important;
      background-clip: text !important;
      color: transparent !important ;
    }