.slideShow {
      width: 100vw !important;
  
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  object-fit: cover;
  overflow: hidden !important;
  padding: 10px 40px;
  text-align: center !important;

  .owl-theme {
     width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-position: bottom;
 
    overflow: hidden;
    height: auto;
     text-align: center !important;
    .item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      background-position: bottom;
      width: 100%;
       text-align: center !important;
      height: auto !important;
    }
  }
}
