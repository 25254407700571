.copyright {
  max-width: 1100px;
  width: 100%;
  height: auto;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;

  .copyright_info {
    width: 100%;
    text-transform: lowercase !important;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span {
      font-size: 10px;
      color: rgb(219, 219, 219);
    }
  }
}
