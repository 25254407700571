.confectioneryBrand {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 40px !important;
  width: 100%;
  height: auto;
  .conf_head {
    width: 100% !important;
    padding: 30px 100px 100px 100px !important;
    text-align: center;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
    .hed_text {
      display: flex;
      text-align: center;
      h1 {
        text-transform: uppercase;
        font-weight: 800;
      }
     
    }
     .hed_sub_hd {
        display: flex;
        text-align: center;
        h2 {
          color: black !important;
        }
      }
  }
  @media screen and (max-width: 1000px) {
    .conf_head {
      padding: 10px 40px !important;
    }
  }
  h2 {
    padding: 0px 0px !important;
    text-transform: uppercase;
    font-weight: 800;
  }
  .confBrand_hero {
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      color: white;
    }
  }
  .__brand_wrp {
    width: 100vw;
    padding: 80px 0px;
    .__brand {
      width: 100%;
      display: grid;
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr;
      .__brand_img {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 100%;
        img {
          width: 80%;
        }
      }
      .__brand_text {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: auto;
        text-align: center;
        img {
          width: 200px;
          margin-bottom: 10px;
        }
        .brand_icons {
          padding-top: 40px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          width: 100%;
          height: auto;
          grid-gap: 30px;
          align-items: center;
          justify-content: center;
          img {
            padding: 20px;
            width: 100px;
          }
        }
        @media screen and (max-width: 1000px) {
          .brand_icons {
            grid-template-columns: 1fr;
          }
        }
      }
      .__brand__sub_text {
        padding: 80px 0px;
        width: 100%;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    @media screen and (max-width: 992px) {
      .__brand {
        grid-template-columns: 1fr;
      }
    }
    .__brand_info {
      text-align: center;
      width: 100%;
      height: auto;
      display: grid;
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      img {
        width: 150px;
      }
    }
    @media screen and (max-width: 1000px) {
      .__brand_info {
        grid-template-columns: 1fr;
      }
    }

    .__brand_info_raja {
      padding: 10px 100px;
      text-align: center;
      width: 100%;
      height: auto;
      display: grid;
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr 1fr;
      img {
        width: 150px;
      }
    }
    @media screen and (max-width: 1000px) {
      .__brand_info_raja {
        grid-template-columns: 1fr;
      }
    }
  }
}
.confBrand {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  .confSectionLeft {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: start;
    text-align: right;
    img {
      padding: 40px;
      width: 100%;
    }
  }
  .confSectionCenter {
    width: 100%;
    height: auto;
    img {
      width: 100%;
    }
  }
  .confSectionRight {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    text-align: left;
    img {
      padding: 40px;
      width: 80%;
    }
  }
}
@media screen and (max-width: 992px) {
  .confBrand {
    grid-template-columns: 1fr;
  }
}

.confectionery {
  width: 100%;
  height: auto;
  .__con_head {
    padding: 50px 100px !important;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    flex-direction: column;
  }
  .__con_main_cat {
    padding: 10px 40px 100px 40px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 60px;
    .__con_cat_card {
      overflow: hidden;
      height: 100%;
      align-items: center;
      justify-content: end;
      cursor: pointer;
      -webkit-transition: all 250ms ease-out;
      transition: all 250ms ease-out;
      display: flex;
      flex-direction: column;
      width: 100%;

      .__con__cat_text {
        background-color: #ffffff !important;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        overflow: hidden;
        h2 {
          padding-top: 10px;
          font-size: 20px !important;
          color: black;
          font-weight: 300 !important;
          transition: 0.2s ease-in;
        }
      }

      .__con_cat_img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        img {
          height: auto;
          object-fit: contain;
        }
      }
    }
    .__con_cat_card:hover {
      filter: opacity(5);
      -webkit-transform: translateY(-1px);
      transform: translateY(-10px);
      -webkit-box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1) !important;
      box-shadow: 0 8px 24px rgba(255, 255, 255, 0.1) !important;
    }
  }
  @media screen and (max-width: 992px) {
    .__con_main_cat {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }
  .conf_wrap {
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;
    padding: 100px 40px !important;
    .conf_txt {
      .conf_text_head {
        padding-bottom: 15px;
        h3 {
          font-weight: 600 !important;
          color: rgb(0, 0, 0);
        }
      }
    }
    .conf_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .conf_wrap {
      grid-template-columns: 1fr;
    }
  }
}
