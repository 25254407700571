.my-navbar {
  width: 100%;
  max-width: 1400px;
  height: auto;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  .navbar {
    display: none;
  }
  .m-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 2rem;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    .m-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .navbar-toggler {
      outline: none;
      border: none;
      padding: 0 !important;
      width: auto !important;
    }
  }

  .wrapper {
    backdrop-filter: blur(5px);
    background: #fddb00;
    // background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255, 255, 255, 0.462) 30%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
    top: 0;
    position: fixed;
    //max-width: 1400px;
    width: 100%;
    padding: 10px 30px;
    display: grid;
    grid-template-columns: 1fr 2fr 3fr;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    .link {
      color: black !important;
      font-size: 27px !important;
      text-transform: uppercase !important;
      font-weight: 800;
    }
  }
  @media only screen and (max-width: 992px) {
    .wrapper {
      display: none;
    }
    .navbar {
      z-index: 999;
      top: 0;
      position: fixed;
      display: block !important;
      width: 100%;
      background-color:  #fddb00;;
    }
    .m-logo {
      width: 100%;
      img {
        width: 100px;
      }
    }

    .icons {
      display: flex;
      width: 100%;
      justify-content: end;
      gap: 15px;
      color: #777;
      cursor: pointer;
      .cartIcon {
        position: relative;

        span {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #2879fe;
          color: white;
          position: absolute;
          right: -10px;
          top: -10px;
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    .link{
    color: black;
    }
    .link:hover {
    transition: all 0.2s ease-out;
    color: #ff0909 !important;
  }
   }
    .link:focus {
    transition: all 0.2s ease-out;
    color: #ff0909 !important;
  }
    
  
  .item:hover {
    transition: all 0.2s ease-out;
    color: #000000 !important;
  }
  .item:focus {
    transition: all 0.2s ease-out;
    color: #fdb653;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    img {
      width: 120px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    float: right;
    width: 100%;
    .icons {
      display: flex;
      gap: 15px;
      color: #777;
      cursor: pointer;
      .cartIcon {
        position: relative;
        span {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: orange;
          color: white;
          position: absolute;
          right: -10px;
          top: -10px;
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }
    }
  }

  .logo-top {
    width: 100%;
    height: auto;
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
