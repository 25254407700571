/* @import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
body {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 100px !important;
  background-color: black !important;
  background: rgb(246, 190, 59);
  background: linear-gradient(
    156deg,
    rgba(246, 190, 59, 1) 0%,
    rgba(216, 64, 2, 1) 45%
  );

  /* background: rgb(2,0,36);
background: linear-gradient(309deg, rgba(2,0,36,1) 28%, rgba(73,0,0,1) 60%, rgba(111,80,0,1) 100%) !important; */

  font-smooth: inherit;
  font-smooth: initial;
  font-smooth: revert;
  font-smooth: revert-layer;
  font-smooth: unset;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 992px) {
  body {
    margin-top: 0px !important;
  }
}

.link {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
  font-style: normal;
  color: #ffffff !important;
  font-size: 20px;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  color: #ffffff !important;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

/* @font-face {
  font-family: "CustomFont";
  src: url("../src/fonts/SaolDisplay-Regular.ttf");
  src: url("../src/fonts/saoldisplay-regular-webfont.woff") format("woff");
  src: url("../src/fonts/saoldisplay-regular-webfont.woff2") format("woff2");
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul {
  text-align: left;
}
p {
  color: #ffffff !important;
  line-height: 1.6;
}

h5 {
  font-weight: "Poppins", sans-serif !important;
}

h1,
h2,
h4,
h5,
h6,
small {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #ffffff !important;
}
span1 {
  font-family: "Poppins", sans-serif;
  color: #fdb653;
}
h1 {
  font-size: 2.5rem;
}
small {
  font-size: 18px;
  color: #ffffff !important;
}
a {
  text-decoration: none !important;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 14px;
}
strong {
  font-weight: 300 !important;
  font-size: 25px;
  line-height: 1;
  color: #8f4634;
}
.__wrap {
  max-width: 1400px;
  padding: 0px 20px;
}
.max-width {
  width: 1300px;
  height: auto;
  flex-direction: column;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
@media only screen and (max-width: 992px) {
  h1 {
    font-size: 2.5rem !important;
    line-height: 1 !important;
  }
  .max-width {
    width: 100%;
    max-width: none !important;
  }
}

@media only screen and (max-width: 992px) {
  body {
    margin-top: 80px !important;
  }
}
.full_width_one {
  width: 100dvw;
  /* background-color: rgb(38, 38, 38) !important; */
  /* background: rgb(246,190,59);
background: linear-gradient(156deg, rgba(246,190,59,1) 0%, rgba(216,64,2,1) 45%, rgba(156,2,139,1) 100%); */
}
.full_width_two {
  width: 100dvw;
  /* background: rgb(252,214,48);
background: linear-gradient(210deg, rgba(252,214,48,1) 0%, rgba(255,113,1,1) 34%, rgba(30,94,38,1) 100%); */
}
.full_width_three {
  width: 100dvw;
  /* background: rgba(255, 255, 255, 0.258);
backdrop-filter: saturate(80%); */
  /*background: linear-gradient(146deg, rgba(252,214,48,1) 0%, rgba(255,113,1,1) 43%, rgba(19,61,179,1) 100%); */
}

.full_width_i {
  width: 100dvw;
  background: #93b6ae;
  /* backdrop-filter: saturate(80%); */
  /* background-color: rgb(38, 38, 38) !important; */
}
.full_width_ii {
  width: 100dvw;
  background: #ab97b4;
  /* backdrop-filter: saturate(80%); */
  /* background-color: rgb(38, 38, 38) !important; */
}
.full_width_iii {
  width: 100dvw;
  background: #b4b197;
  /* backdrop-filter: saturate(80%); */
  /* background-color: rgb(38, 38, 38) !important; */
}
.full_width_iv {
  width: 100dvw;
  background: #b49797;
  /* backdrop-filter: saturate(80%); */
  /* background-color: rgb(38, 38, 38) !important; */
}
.__container {
  margin: auto;
  max-width: 1300px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  object-fit: contain;
  height: auto;
  margin: auto;
}
button {
  color: #fdb653 !important;
}
.owl-next {
  span {
    padding: 20px;
  }
}
.owl-prev {
  span {
    padding: 20px;
  }
}

/* .readMore{
  color: black !important;
} */
