.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .modal-content {
    position: relative;
    overflow-y: scroll !important;
    padding: 20px;
    max-width: 780px !important;
    max-height: 80%;
    margin: 0px 50px;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    overflow: auto;
    .close-button {
      color:orange;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .close-button:hover {
      color:black;
      text-decoration: none;
    } 

    .form_con {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;

      form {
        border-radius: 50px;
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.46);
        backdrop-filter: blur(5px);
        flex-direction: column;
        display: flex;
        h1{
          line-height: .8 !important;
          padding-bottom: 10px;
        }
        input {
           width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            padding: 0;
            border: none;
            outline: none;
            box-sizing: border-box;
            background: #ecf0f3;
            padding: 10px;
            margin-bottom: 15px;
            height: 40px;
            font-size: 16px;
            border-radius: 50px;
            box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
          
        }
        button {
          border-radius: 30px;
          color: white;
          width: 100%;
          height: 40px;
           background-color: orange;
        }
        button:hover {
          background-color: grey;
         
          transition: 0.3s ease-in-out;
          text-decoration: none;
          color: rgb(255, 255, 255);
        }
        span{
          font-size: 12px;
          padding-top: 10px;
          padding-left: 10px;
          cursor: pointer;
        }
        span:hover{
          color: rgb(102, 102, 102);
        }
      }
    }
    @media screen and (max-width:992px) {
      .form_con{
        grid-template-columns: 1fr;
        text-align: center;

      }
      
    }
  }
}
button {
  font-weight: 600;
  border: none;
  background: none;
}
button:hover {
  text-decoration: underline;
}
