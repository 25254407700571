.sub_confectionery {
  width: 100%;
  height: auto;
  .__sub__con_head {
    padding: 40px 200px;
    text-align: center!important;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 40px !important;
    }
  }
  @media screen and (max-width:992px) {
    .__sub__con_head{
       padding: 40px ;
    }
    
  }
  .__sub__con_main_cat {
    padding: 10px 40px 100px 40px;
    width: 100%;
    height: auto;
    display: grid;
  align-items: self-start;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 60px;
    .__sub__con_cat_card {
      overflow: hidden;
      height: 100%;
      align-items: center;
      justify-content: end;
      cursor: pointer;
      -webkit-transition: all 250ms ease-out;
      transition: all 250ms ease-out;
      display: flex;
      flex-direction: column;
      width: 100%;

      .__sub__con__cat_text {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        overflow: hidden;
        h2 {
          padding-top: 10px;
          font-size: 20px !important;
          color: white !important;
          font-weight: 800 !important;
          transition: 0.2s ease-in;
          text-transform: uppercase;
          text-align: center;
        }
      }

      .__sub__con_cat_img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .__sub__con_cat_card:hover {
      filter: opacity(5) !important;
      -webkit-transform: translateY(-1px) !important;
      transform: translateY(-10px) !important;
    }
  }

  @media screen and (max-width: 992px) {
    .__sub__con_main_cat {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }
  
  .sub_wrap {
    width: 100%;
    height: auto;
    padding: 100px 40px;


    .sub__conf_wrap {
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      display: grid;
      grid-gap: 100px;
      grid-template-columns: 1fr 1fr !important;
      .sub__conf_txt {
        width: 100%;
        height: auto;
        display: flex;
        
        flex-direction: column;
        .sub__conf_text_head {
          padding-bottom: 15px;
          h1{
            color: #ffffff !important;
            text-transform: uppercase;
            font-weight: 800 !important;
          }
          h2{
            color: #000000 !important;
            text-transform: uppercase;
            font-weight: 800 !important;
          }

        }
         p{
             color: white !important;
          }
      }
      .sub__conf_img {
        width: 100%;
        height: auto;
        img {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .sub__conf_wrap {
        grid-template-columns: 1fr !important;
      }
    }
    .sub__sub__text {
      padding: 10px 0px;
      width: 100%;
      height: auto;
      h2 {
        font-size: 25px;
        padding-bottom: 40px;
        color: rgb(0, 0, 0) !important;
      }

      .sub_sub_in_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        grid-gap: 50px;
        height: auto;
        .sub_sub_in_wrap_sec {
          text-align: center;
          img {
            width: 200px;
            padding-bottom: 30px;
          }
        }
      }
      @media screen and (max-width: 992px) {
      .sub_sub_in_wrap {
        grid-template-columns: 1fr;
      }
    }
    }
    
  }
  .sub__conf_wrap_one {
    padding: 100px 40px;
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 60px;
    grid-template-columns: 1fr 1fr;
    .sub__conf_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 992px) {
      .sub__conf_wrap_one {
        grid-template-columns: 1fr;
      }
    }
}
