.contact {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  padding: 60px 0px;

  .contact_info_wrap {
    padding: 60px;
    width: 100%;
    height: auto;
    .con_data {
      padding-bottom: 20px;
    }

    h1 {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 800;
      padding-bottom: 20px;
    }
    .contact_info {
      padding: 10px 30px;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 3fr;
    }
  }
  .contact_form {
    padding: 60px;
    width: 100%;
    height: auto;
  //  background-color: #414141 !important;
    input {
      margin: 10px 0px;
      padding: 10px;
      font-size: 14px;
      border-radius: 30px;
   background-color: #ffffff !important;
      border: none;
      color: white;
    }
    .form_in {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
    }
    textarea {
      font-family: Helvetica, sans-serif;
   background-color: #ffffff !important;
      width: 100%;
      height: 100px;
      padding: 10px 10px;
      border-radius: 20px;
      font-size: 14px;
      border: none;
    }
    button {
      background-color: orange;
      border: none;
      padding: 10px 30px;
      border-radius: 30px;
      color: rgb(0, 0, 0) !important;
    } 
  }
}
@media only screen and (max-width: (992px)) {
  .contact {
    grid-template-columns: 1fr;
    padding: 10px !important;
    margin-top: 30px;
    .contact_info_wrap {
      padding: 20px;
      .contact_info {
        padding: 20px !important;
        .con_data {
          padding: 20px;
        }
        .con_icon {
          padding: 5px !important;
           //background-color: #ffa500 !important;
           color:black !important ;
        }
      }
    }
    .contact_form {
      padding: 50px;
    }
  }
}
