

.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .link {
    text-decoration: none;
    color: inherit;
  }
}
